
import { computed, defineComponent, onBeforeMount, ref } from 'vue'
import HomeLibraryContentsByCategory from '@/components/home/HomeLibraryContentsByCategory.vue'
import { useFilters } from '@/api/groups/useFilters'
import { useCategory } from '@/composables/useCategory'
import { useProfile } from '@/composables/profile/useProfile'
import { parse, isAfter } from 'date-fns'

type Subcategory = {
  code: string;
  label: string;
}

export default defineComponent({
  components: {
    HomeLibraryContentsByCategory
  },

  setup () {
    const subcategory = ref<Subcategory|null>(null)
    const {
      categoriesList
    } = useFilters()

    const {
      profile,
      lockAccess
    } = useProfile()

    const { getSubCategory } = useCategory()

    onBeforeMount(async () => {
      try {
        const findedSubCategory = await getSubCategory(profile.value.category.uid)
        subcategory.value = {
          code: findedSubCategory.uid,
          label: findedSubCategory.name
        }
      } catch (error) {
        console.log(error)
        subcategory.value = {
          code: 'qoG4RVIX2ZkssMTxvawM',
          label: 'Alta performance e ajuda'
        }
      }
    })

    const templateContentsCategory = computed(() => {
      return categoriesList.value.find((category) => category.code === subcategory.value?.code)
    })

    const sazonalContentsCategory = computed(() => {
      const result = categoriesList.value
        .filter((category) => category.type === 'sazonal')
        .map((category) => ({
          ...category,
          relatedDate: parse(`${category?.relatedDate}/${new Date().getFullYear()}`, 'dd/MM/yyyy', new Date())
        }))
        .sort((a, b) => a.relatedDate.getTime() - b.relatedDate.getTime())
        .find((category) => isAfter(category.relatedDate, new Date()))

      return result
    })

    return {
      templateContentsCategory,
      sazonalContentsCategory,
      lockAccess
    }
  }
})
