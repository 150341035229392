
import { defineComponent } from 'vue'
import PostCard from '@/components/posts/PostCard.vue'

// @ts-ignore
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore, { Navigation } from 'swiper'

SwiperCore.use([Navigation])

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
    PostCard
  },

  props: {
    posts: {
      type: Object,
      required: true
    },
    loaded: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: false
    },
    rejectable: {
      type: Boolean,
      default: false
    },
    approvable: {
      type: Boolean,
      default: false
    },
    lock: {
      type: Boolean,
      default: false
    }
  },

  setup (props, { slots }) {
    const swiperSettings = {
      loop: false,
      slidesPerView: 1,
      slideToClickedSlide: true,
      preventInteractionOnTransition: true,
      freeMode: true,
      breakpoints: {
        640: {
          slidesPerView: 2
        },
        1024: {
          slidesPerView: 2
        },
        1280: {
          slidesPerView: 3
        },
        1536: {
          slidesPerView: 4
        }
      }
    }

    const hasSlot = (name: string) => {
      return !!slots[name]
    }

    return {
      swiperSettings,
      hasSlot
    }
  }
})
