<template>
  <div
    class="section-header"
  >
    <h2>{{ $t('page.home.section_tools_title') }}</h2>
  </div>
  <div class="grid lg:grid-cols-3 gap-4">
    <template v-for="(tool, index) in homeToolsList" :key="index">
      <manybot-input-modal
        v-if="tool.access.type === 'modal'"
        :task="tool.access.task"
        :target="tool.access.target"
      >
        <template v-slot:button-content>
          <div class="card card-hover flex flex-col items-start justify-center h-full">
            <tools-card-header
              :icon="tool.icon"
              :title="$t(tool.title)"
              :bgColor="tool.background"
            />
            <div class="card-body pt-4 text-left">
              <p v-html="$t(tool.description)" />
            </div>
          </div>
        </template>
      </manybot-input-modal>

      <router-link
        v-if="tool.access.type === 'route'"
        :to="{ name: tool.access.route }"
      >
        <div class="card card-hover flex flex-col items-start justify-center h-full">
          <tools-card-header
            :icon="tool.icon"
            :title="$t(tool.title)"
            :bgColor="tool.background"
          />
          <div class="card-body pt-4 text-left">
            <p v-html="$t(tool.description)" />
          </div>
        </div>
      </router-link>
    </template>
  </div>

  <router-link
    class="btn btn-secondary my-8 w-max mx-auto"
    :to="{ name: 'contents', hash: '#tools' }"
  >
    {{ $t('page.home.section_tools_action_more') }}
  </router-link>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ToolsCardHeader from '../tools/ToolsCardHeader.vue'
import { useTools } from '@/composables/tools/useTools'
import ManybotInputModal from '@/components/manyBot/ManybotInputModal.vue'

export default defineComponent({
  components: { ToolsCardHeader, ManybotInputModal },
  setup () {
    const { homeToolsList } = useTools()

    return {
      homeToolsList
    }
  }
})
</script>

<style scoped>
@layer components {
  a {
    @apply hover:no-underline;
  }
}
</style>
