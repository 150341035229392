
import HomeTools from '@/components/home/HomeTools.vue'
import { getAnalytics } from '@/composables/analytics/useAnalytics'
import { defineComponent } from 'vue'
import DashboardContent from '../components/dashboard/DashboardContent.vue'
// import HomeAccount from '@/components/home/HomeAccount.vue'
// import HomeBunner from '@/components/home/HomeBunner.vue'
import HomePersonalContents from '@/components/home/HomePersonalContents.vue'
import HomeMyContents from '@/components/home/HomeMyContents.vue'
import HomeLibraryContents from '@/components/home/HomeLibraryContents.vue'
// import HomeFacebookPermissionsAlert from '@/components/home/HomeFacebookPermissionsAlert.vue'

export default defineComponent({
  components: {
    HomePersonalContents,
    HomeLibraryContents,
    HomeMyContents,
    DashboardContent,
    HomeTools,
    // HomeAccount,
    // HomeBunner,
    // HomeFacebookPermissionsAlert
  },

  setup() {
    const {
      analytics
    } = getAnalytics()

    return {
      analytics
    }
  }
})
