
import { useReviewPosts } from '@/api/publications/usePosts'
import { computed, defineComponent, onMounted, unref } from 'vue'
import HomePostsContainer from '@/components/home/HomePostsContainer.vue'
import { useI18n } from 'vue-i18n';
import { usePaidQuota } from '@/composables/profile/useQuota'
import { useProfile } from '@/composables/profile/useProfile'
import BlockedReviewBanner from '@/components/posts/blockedReviewBanner.vue'

export default defineComponent({
  components: {
    HomePostsContainer,
    BlockedReviewBanner,
  },

  setup () {
    const { t } = useI18n()
    
    const {
      isPostsLoaded,
      posts,
      fetchPosts
    } = useReviewPosts()

    const {
      quota,
      birtday
    } = usePaidQuota()

    const {
      lockAccess,
      userRules
    } = useProfile()

    const isEmpty = computed(() => {
      return unref(isPostsLoaded) && unref(posts).length === 0
    })

    const hasNoPostMessage = computed(() => {
      const messages = {
        creating: t('state.reviews.creating_posts_message-html'),
        alreadUsed: t('state.reviews.alread_review_posts_message-html', { birtday }),
        noContent: t('state.reviews.no_posts_message-html', { birtday })
      }
      if (unref(quota).limit === 0) {
        return messages.noContent
      } else if (unref(quota).current < unref(quota).limit) {
        return messages.creating
      }
      return messages.alreadUsed
    })

    const canReviewContent = computed(() => {
      return !lockAccess.value || userRules.value.canReviewContent
    })

    onMounted(async () => {
      await fetchPosts()
    })
    
    return {
      posts,
      isPostsLoaded,
      isEmpty,
      hasNoPostMessage,
      canReviewContent
    }
  }
})
