<template>
  <div class="flex justify-between items-center mb-4">
    <div>
      <h3>{{ categoryLabel }}</h3>
    </div>
    <div class="flex justify-end">
      <router-link :to="{ name: 'ready-to-use-posts' }">
        {{ $t('action.see_more') }}
      </router-link>
    </div>
  </div>
  
  <home-posts-container
    v-if="!isEmpty"
    :posts="posts"
    :loaded="isPostsLoaded"
    :lock="lockAccess"
    approvable
  />
</template>

<script lang="ts">
import { useFreePosts } from '@/api/publications/usePosts'
import { computed, defineComponent, onMounted, unref } from 'vue'
import HomePostsContainer from '@/components/home/HomePostsContainer.vue'

export default defineComponent({
  components: {
    HomePostsContainer,
  },
  props: {
    categoryCode: {
      type: String,
      required: false 
    },
    categoryLabel: {
      type: String,
      required: false 
    },
    lockAccess: {
      type: Boolean,
      default: true
    }
  },

  setup (props) {
    
    const {
      isPostsLoaded,
      posts,
      fetchPosts
    } = useFreePosts()

    const isEmpty = computed(() => {
      return unref(isPostsLoaded) && unref(posts).length === 0
    })

    onMounted(async () => {
      if (props.categoryCode) {
        await fetchPosts({group: props.categoryCode, size: 10})
      }
    })
    
    return {
      posts,
      isPostsLoaded,
      isEmpty,
    }
  }
})
</script>
