
import { useMyPosts } from '@/api/publications/usePosts'
import { computed, defineComponent, onMounted, unref } from 'vue'
import HomePostsContainer from '@/components/home/HomePostsContainer.vue'

export default defineComponent({
  components: {
    HomePostsContainer,
  },

  setup () {

    const {
      isPostsLoaded,
      fetchPosts,
      preview: posts
    } = useMyPosts()

    const isEmpty = computed(() => {
      return unref(isPostsLoaded) && unref(posts).length === 0
    })

    onMounted(async () => {
      await fetchPosts()
    })
    
    return {
      posts,
      isPostsLoaded,
      isEmpty
    }
  }
})
