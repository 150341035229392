<template>
  <dashboard-content full-wrapper :breadcrumb="false">
    <div id="home">
      <!-- <home-facebook-permissions-alert /> -->
      <!-- <home-account /> -->
      <!-- <home-bunner /> -->
      <home-personal-contents />
      <home-my-contents />
      <section>
        <home-tools />
      </section>
      <home-library-contents />
      <!-- <blog-posts /> -->
    </div>
  </dashboard-content>
</template>

<script lang="ts">
import HomeTools from '@/components/home/HomeTools.vue'
import { getAnalytics } from '@/composables/analytics/useAnalytics'
import { defineComponent } from 'vue'
import DashboardContent from '../components/dashboard/DashboardContent.vue'
// import HomeAccount from '@/components/home/HomeAccount.vue'
// import HomeBunner from '@/components/home/HomeBunner.vue'
import HomePersonalContents from '@/components/home/HomePersonalContents.vue'
import HomeMyContents from '@/components/home/HomeMyContents.vue'
import HomeLibraryContents from '@/components/home/HomeLibraryContents.vue'
// import HomeFacebookPermissionsAlert from '@/components/home/HomeFacebookPermissionsAlert.vue'

export default defineComponent({
  components: {
    HomePersonalContents,
    HomeLibraryContents,
    HomeMyContents,
    DashboardContent,
    HomeTools,
    // HomeAccount,
    // HomeBunner,
    // HomeFacebookPermissionsAlert
  },

  setup() {
    const {
      analytics
    } = getAnalytics()

    return {
      analytics
    }
  }
})
</script>

<style>
@layer components {
  #home section {
    @apply px-4 py-8 overflow-x-hidden;

    .section-header {
      @apply mb-16;
      
      h2 {
        @apply text-xl;
      }
    }
  }
}

</style>
