
import { defineComponent } from 'vue'
import ToolsCardHeader from '../tools/ToolsCardHeader.vue'
import { useTools } from '@/composables/tools/useTools'
import ManybotInputModal from '@/components/manyBot/ManybotInputModal.vue'

export default defineComponent({
  components: { ToolsCardHeader, ManybotInputModal },
  setup () {
    const { homeToolsList } = useTools()

    return {
      homeToolsList
    }
  }
})
