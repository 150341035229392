<template>
  <section>
    <div class="section-header flex justify-between items-center">
      <div>
        <h2>{{ $t('page.posts.post_read_to_use_title') }}</h2>
        <small
          v-html="$t('page.posts.post_read_to_use_description')"
        />
      </div>
    </div>

    <div class="flex flex-col gap-8">
      <div>
        <HomeLibraryContentsByCategory 
          v-if="templateContentsCategory?.code"
          :categoryCode="templateContentsCategory?.code"
          :categoryLabel="templateContentsCategory?.label"
          :lockAccess="lockAccess"
        />
      </div>
      <div>
        <HomeLibraryContentsByCategory 
          v-if="sazonalContentsCategory?.code"
          :categoryCode="sazonalContentsCategory?.code"
          :categoryLabel="sazonalContentsCategory?.label"
          :lockAccess="lockAccess"
        />
      </div>
      
      <div
        class="text-center text-xl text-gray-600 mx-auto w-10/12"
        v-html="$t('page.home.section_free_content_description-html')"
      />
  
      <router-link
        class="btn btn-secondary btn-lg my-8 w-max mx-auto border-2 text-gray-700 hover:text-gray-600 bg-transparent hover:bg-transparent"
        :to="{ name: 'ready-to-use-posts' }"
      >
        {{ $t('page.home.section_free_content_action_more') }}
      </router-link>
    </div>


  </section>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeMount, ref } from 'vue'
import HomeLibraryContentsByCategory from '@/components/home/HomeLibraryContentsByCategory.vue'
import { useFilters } from '@/api/groups/useFilters'
import { useCategory } from '@/composables/useCategory'
import { useProfile } from '@/composables/profile/useProfile'
import { parse, isAfter } from 'date-fns'

type Subcategory = {
  code: string;
  label: string;
}

export default defineComponent({
  components: {
    HomeLibraryContentsByCategory
  },

  setup () {
    const subcategory = ref<Subcategory|null>(null)
    const {
      categoriesList
    } = useFilters()

    const {
      profile,
      lockAccess
    } = useProfile()

    const { getSubCategory } = useCategory()

    onBeforeMount(async () => {
      try {
        const findedSubCategory = await getSubCategory(profile.value.category.uid)
        subcategory.value = {
          code: findedSubCategory.uid,
          label: findedSubCategory.name
        }
      } catch (error) {
        console.log(error)
        subcategory.value = {
          code: 'qoG4RVIX2ZkssMTxvawM',
          label: 'Alta performance e ajuda'
        }
      }
    })

    const templateContentsCategory = computed(() => {
      return categoriesList.value.find((category) => category.code === subcategory.value?.code)
    })

    const sazonalContentsCategory = computed(() => {
      const result = categoriesList.value
        .filter((category) => category.type === 'sazonal')
        .map((category) => ({
          ...category,
          relatedDate: parse(`${category?.relatedDate}/${new Date().getFullYear()}`, 'dd/MM/yyyy', new Date())
        }))
        .sort((a, b) => a.relatedDate.getTime() - b.relatedDate.getTime())
        .find((category) => isAfter(category.relatedDate, new Date()))

      return result
    })

    return {
      templateContentsCategory,
      sazonalContentsCategory,
      lockAccess
    }
  }
})
</script>
