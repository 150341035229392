
import { useFreePosts } from '@/api/publications/usePosts'
import { computed, defineComponent, onMounted, unref } from 'vue'
import HomePostsContainer from '@/components/home/HomePostsContainer.vue'

export default defineComponent({
  components: {
    HomePostsContainer,
  },
  props: {
    categoryCode: {
      type: String,
      required: false 
    },
    categoryLabel: {
      type: String,
      required: false 
    },
    lockAccess: {
      type: Boolean,
      default: true
    }
  },

  setup (props) {
    
    const {
      isPostsLoaded,
      posts,
      fetchPosts
    } = useFreePosts()

    const isEmpty = computed(() => {
      return unref(isPostsLoaded) && unref(posts).length === 0
    })

    onMounted(async () => {
      if (props.categoryCode) {
        await fetchPosts({group: props.categoryCode, size: 10})
      }
    })
    
    return {
      posts,
      isPostsLoaded,
      isEmpty,
    }
  }
})
